// client/src/components/EmailManagement.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EmailManagement = () => {
  const [email, setEmail] = useState('');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/email`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setEmail(response.data.email);
      } catch (error) {
        console.error('Fetch email error:', error);
      }
    };

    fetchEmail();
  }, [token]); // 'token' is included in the dependency array

  const handleSaveEmail = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/email/save-email`, { email }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Email saved successfully');
    } catch (error) {
      console.error('Save email error:', error);
    }
  };

  return (
    <form onSubmit={handleSaveEmail}>
      <div className="mb-3">
        <label className="form-label">Email</label>
        <input
          type="email"
          className="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="btn btn-primary">Save Email</button>
    </form>
  );
};

export default EmailManagement;
