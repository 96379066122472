import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MediaInsights.css';

const MediaInsights = () => {
  const [insights, setInsights] = useState([]);
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        const response = await axios.get('/api/insights');
        setInsights(Array.isArray(response.data) ? response.data : []); // Ensure insights is an array
      } catch (error) {
        console.error('Error fetching insights:', error);
      }
    };
    fetchInsights();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/insights', { title, summary, content });
      setInsights([...insights, response.data]);
      setTitle('');
      setSummary('');
      setContent('');
    } catch (error) {
      console.error('Error submitting insight:', error);
    }
  };

  return (
    <div className="insights-section">
      <h2>Media Insights</h2>
      <p>Welcome to the Media Insights page. We invite you to submit high-quality opinion pieces regarding media in general. Your insights and analyses on various media topics are valuable to us.</p>
      <div className="insights-list">
        {insights.map((insight, index) => (
          <div key={index} className="insight-item">
            <h3>{insight.title}</h3>
            <p>{insight.summary}</p>
            <a href={`/insights/${insight._id}`} className="read-more">Read more</a>
          </div>
        ))}
      </div>
      <div className="submit-insight">
        <h3>Submit Your Insight</h3>
        <form onSubmit={handleSubmit} className="insight-form">
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="summary">Summary:</label>
            <textarea
              id="summary"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="content">Content:</label>
            <textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-button">Submit</button>

        </form>
      </div>
    </div>
  );
};

export default MediaInsights;
