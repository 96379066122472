import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './TopicList.css';

const generateUrl = (name) => {
  if (!name) {
    console.error('Name is undefined or null:', name);
    return ''; // Return a default value or handle it as necessary
  }
  return name.toLowerCase().replace(/ /g, '-');
};

const TopicList = () => {
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    axios.get(`${apiUrl}/api/topics`)
      .then(response => {
        console.log('Fetched topics:', response.data);
        setTopics(response.data);
      })
      .catch(error => console.error('Error fetching topics:', error));
  }, []);

  return (
    <div className="container mt-5">
      <Helmet>
        <title>Topics - The Other Side</title>
        <meta name="description" content="Explore a variety of topics with balanced perspectives on major political and social issues." />
        <meta name="keywords" content="topics, balanced perspectives, political issues, social issues, curated content" />
        <link rel="canonical" href="https://www.theotherside.media/topics" />
      </Helmet>
      <div className="topic-grid">
        {topics.map(topic => (
          <div className="topic-item" key={topic._id}>
            <h5>
              <Link to={`/topics/${generateUrl(topic.name)}`}>{topic.name}</Link>
            </h5>
            <hr />
            <ul className="list-unstyled">
              {Array.isArray(topic.subtopics) && topic.subtopics.map(subtopic => (
                <li key={subtopic._id}>
                  <Link to={`/subtopics/${subtopic._id}`} className="subtopic-link">{subtopic.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopicList;
