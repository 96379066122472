import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa'; // Using FontAwesome for search icon

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleIconClick = () => {
    setExpanded(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 300); // Ensure the input is fully expanded before focusing
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(query);
    navigate('/search');
    setExpanded(false); // Collapse the input field after search
  };

  const handleBlur = () => {
    if (query === '') {
      setExpanded(false);
    }
  };

  return (
    <div className="search-container">
      <FaSearch className="search-icon" onClick={handleIconClick} />
      <form onSubmit={handleSubmit} className="search-form">
        <input
          ref={inputRef}
          type="search"
          className={`form-control search-input ${expanded ? 'expanded' : ''}`}
          placeholder="Search..."
          aria-label="Search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onBlur={handleBlur}
        />
      </form>
    </div>
  );
};

export default SearchBar;
