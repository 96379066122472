import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './SubtopicDetail.css';

const generateUrl = (name) => {
  if (!name) {
    console.error('Name is undefined or null:', name);
    return '';
  }
  return name.toLowerCase().replace(/ /g, '-').replace(/\//g, '-');
};

const normalizeIdentifier = (identifier) => {
  return identifier.replace(/-/g, ' ').replace(/%20/g, ' ');
};

const SubtopicDetail = ({ user }) => {
  const { identifier } = useParams();
  const [subtopic, setSubtopic] = useState(null);
  const [parentTopic, setParentTopic] = useState(null);
  const [showOptionOne, setShowOptionOne] = useState(true);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const normalizedIdentifier = normalizeIdentifier(identifier);

    const fetchSubtopic = async () => {
      try {
        const subtopicResponse = await axios.get(`${apiUrl}/api/subtopics/${normalizedIdentifier}`);
        const subtopicData = subtopicResponse.data;

        if (subtopicData) {
          setSubtopic(subtopicData);

          // Fetch all topics to find the parent topic
          const topicsResponse = await axios.get(`${apiUrl}/api/topics`);
          const topics = topicsResponse.data;
          const parent = topics.find(topic => topic.subtopics.some(sub => sub._id === subtopicData._id || normalizeIdentifier(sub.name) === normalizedIdentifier));
          setParentTopic(parent);
          fetchComments(subtopicData._id);
        } else {
          console.error('No subtopic found with the given identifier');
        }
      } catch (error) {
        console.error('Error fetching subtopic:', error);
      }
    };

    fetchSubtopic();
  }, [identifier]);

  const fetchComments = async (subtopicId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/subtopics/${subtopicId}/comments`);
      if (Array.isArray(response.data)) {
        setComments(response.data.filter(comment => comment.content && comment.content.trim() && comment.username));
      } else {
        console.error('Expected array but received:', response.data);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = process.env.REACT_APP_API_URL;
    const normalizedIdentifier = normalizeIdentifier(identifier);

    try {
      console.log('Submitting comment:', newComment, 'Username:', user.username);
      const response = await axios.post(`${apiUrl}/api/subtopics/${normalizedIdentifier}/comments`, { content: newComment, username: user.username });
      if (response.data && response.data.content) {
        setComments([...comments, response.data]);
        setNewComment('');
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  if (!subtopic) return <div className="container mt-5">Loading...</div>;

  const isToggleSubtopic = subtopic.name === "Primary Cause of Russia's 2022 Invasion of Ukraine";

  return (
    <div className="subtopic-container">
      <Helmet>
        <title>{subtopic.name} - The Other Side</title>
        <meta name="description" content={`Explore balanced perspectives and comprehensive information on ${subtopic.name}.`} />
        <meta name="keywords" content={`balanced perspectives, ${subtopic.name}, ${parentTopic ? parentTopic.name : ''}`} />
        <link rel="canonical" href={`https://www.theotherside.media/subtopics/${generateUrl(subtopic.name)}`} />
      </Helmet>
      <div className="subtopic-header">
        <h2>{subtopic.name}</h2>
        <p className="centered-description">{subtopic.description}</p>
        {parentTopic && (
          <Link to={`/topics/${generateUrl(parentTopic.name)}`} className="back-link">Back to {parentTopic.name}</Link>
        )}
      </div>
      {isToggleSubtopic && (
        <div className="viewpoint-toggle">
          <label className="toggle-label">{showOptionOne ? 'Putin\'s desire to return Russia to Power' : 'NATO expansion and western policies'}</label>
          <label className="switch">
            <input
              type="checkbox"
              checked={showOptionOne}
              onChange={() => setShowOptionOne(!showOptionOne)}
            />
            <span className="slider"></span>
          </label>
        </div>
      )}
      <div className="media-section">
        <h3>Videos</h3>
        <div className="media-grid">
          {isToggleSubtopic ? (
            showOptionOne ? (
              Array.isArray(subtopic.videos) && subtopic.videos.map((video, index) => (
                <div key={index} className="media-item">
                  <h4>{video.title}</h4>
                  <p>{video.summary}</p>
                  <div className="media-wrapper">
                    <iframe
                      src={`https://www.youtube.com/embed/${new URL(video.link).searchParams.get('v')}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={video.title}
                    ></iframe>
                  </div>
                </div>
              ))
            ) : (
              Array.isArray(subtopic.opposingVideos) && subtopic.opposingVideos.map((video, index) => (
                <div key={index} className="media-item">
                  <h4>{video.title}</h4>
                  <p>{video.summary}</p>
                  <div className="media-wrapper">
                    <iframe
                      src={`https://www.youtube.com/embed/${new URL(video.link).searchParams.get('v')}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={video.title}
                    ></iframe>
                  </div>
                </div>
              ))
            )
          ) : (
            Array.isArray(subtopic.videos) && subtopic.videos.map((video, index) => (
              <div key={index} className="media-item">
                <h4>{video.title}</h4>
                <p>{video.summary}</p>
                <div className="media-wrapper">
                  <iframe
                    src={`https://www.youtube.com/embed/${new URL(video.link).searchParams.get('v')}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={video.title}
                  ></iframe>
                </div>
              </div>
            ))
          )}
        </div>
        <h3>Audio</h3>
        <div className="media-grid">
          {Array.isArray(subtopic.audio) && subtopic.audio.map((audio, index) => (
            <div key={index} className="media-item">
              <h4>{audio.title}</h4>
              <p>{audio.summary}</p>
              {audio.embed ? (
                <div dangerouslySetInnerHTML={{ __html: audio.embed }} />
              ) : (
                <div className="media-wrapper">
                  <audio controls>
                    <source src={audio.link} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="comments-section">
        <h3>Discussion Section</h3>
        <ul>
          {Array.isArray(comments) && comments.map((comment, index) => (
            <li key={index}><strong>{comment.username}:</strong> {comment.content}</li>
          ))}
        </ul>
        {user && (
          <form onSubmit={handleCommentSubmit}>
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Express your thoughts..."
              required
            />
            <button type="submit">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SubtopicDetail;