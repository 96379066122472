// client/src/components/Footer.js

import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <p>&copy; 2024 The Other Side. All rights reserved.</p>
      </div>

    </footer>
  );
};

export default Footer;
