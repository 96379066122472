import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './TopicDetail.css';

const normalizeName = (name) => name.replace(/-/g, ' ').replace(/%20/g, ' ');

const generateUrl = (name) => name.toLowerCase().replace(/ /g, '-').replace(/\//g, '-');

const TopicDetail = () => {
  const { name } = useParams();
  const [topic, setTopic] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const normalizedName = normalizeName(name);

    console.log('Fetching topic:', normalizedName);

    axios.get(`${apiUrl}/api/topics/${normalizedName}`)
      .then(response => {
        console.log('Fetched topic data:', response.data);
        if (response.data) {
          setTopic(response.data);
          fetchQuestions(response.data._id);
        } else {
          console.error('No topic found with the given name');
        }
      })
      .catch(error => console.error('Error fetching topic:', error));
  }, [name]);

  const fetchQuestions = async (topicId) => {
    try {
      const response = await axios.get(`/api/topics/${topicId}/questions`);
      setQuestions(response.data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const fetchAnswer = async (question) => {
    setAnswer('Loading...');
    try {
      const response = await axios.post('/api/chatbot', { question });
      setAnswer(response.data.answer);
    } catch (error) {
      console.error('Error fetching answer:', error);
      setAnswer('Sorry, there was an error fetching the answer.');
    }
  };

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    fetchAnswer(question);
  };

  if (!topic) return <div className="container mt-5">Loading...</div>;

  return (
    <div className="topic-detail-container">
      <Helmet>
        <title>{topic.name} - The Other Side</title>
        <meta name="description" content={`Explore balanced perspectives and comprehensive information on ${topic.name}.`} />
        <meta name="keywords" content={`balanced perspectives, ${topic.name}, ${topic.subtopics && topic.subtopics.map(sub => sub.name).join(', ')}`} />
        <link rel="canonical" href={`https://www.theotherside.media/topics/${generateUrl(topic.name)}`} />
      </Helmet>
      <div className="topic-content">
        <div className="topic-header">
          <h2>{topic.name}</h2>
          <p className="centered-description">{topic.description}</p>
        </div>
        <div className="subtopics-container">
          <h3>Subtopics</h3>
          <div className="subtopics-grid">
            {topic.subtopics && topic.subtopics.length > 0 ? (
              topic.subtopics.map((subtopic, index) => (
                <div key={index} className="subtopic-card">
                  <h4>{subtopic.name}</h4>
                  <Link to={`/subtopics/${subtopic._id}`} className="learn-more-button">Learn More</Link>
                </div>
              ))
            ) : (
              <p>No subtopics found.</p>
            )}
          </div>
        </div>
      </div>
      <div className="chatbot-container">
        <h3 className="chatbot-header">Ask Questions</h3>
        <div className="questions-container">
          {questions.map((q, index) => (
            <button
              key={index}
              className="question-button"
              onClick={() => handleQuestionClick(q.question)}
            >
              {q.question}
            </button>
          ))}
        </div>
        {selectedQuestion && (
          <div className="answer-container">
            <h4>Question:</h4>
            <p>{selectedQuestion}</p>
            <h4>Answer:</h4>
            <p>{answer}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopicDetail;
