import React from 'react';
import { Link } from 'react-router-dom';
import './SearchResults.css';

const generateUrl = (name) => {
  if (!name) {
    console.error('Name is undefined or null:', name);
    return ''; // Return a default value or handle it as necessary
  }
  return name.toLowerCase().replace(/ /g, '-').replace(/\//g, '-');
};

const highlightText = (text, highlight) => {
  if (!text || !highlight) return text;
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? <strong key={index}>{part}</strong> : part
  );
};

const SearchResults = ({ results }) => {
  const query = new URLSearchParams(window.location.search).get('q');

  return (
    <div>
      <h2>Search Results</h2>
      {results.length > 0 ? (
        results.map((result, index) => (
          <div key={index} className="result-container">
            <h3>
              <Link to={`/topics/${generateUrl(result.name)}`}>
                {highlightText(result.name, query)}
              </Link>
            </h3>
            <p>{highlightText(result.description, query)}</p>
            {result.subtopics && result.subtopics.length > 0 && (
              <div>
                <ul>
                  {result.subtopics.map((subtopic, subIndex) => (
                    <li key={subIndex}>
                      {highlightText(subtopic.title, query)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
};

export default SearchResults;
